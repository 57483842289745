import { createActionGroup, props } from '@ngrx/store';
import { Skill } from 'src/app/shared/models';

export const SkillActions = createActionGroup({
  source: 'Skill',
  events: {
    'Load Skills': props<{ agencyId: string }>(),
    'Load Skills Success': props<{ skills: Skill[] }>(),
    'Load Skills Failure': props<{ error: Error | null }>(),
    'Load Skill': props<{ skillId: string; agencyId: string }>(),
    'Load Skill Success': props<{ skill: Skill }>(),
    'Load Skill Failure': props<{ error: Error | null }>(),
    'Add Skill': props<{
      agencyId: string;
      skill: Skill | Partial<Skill>;
    }>(),
    'Add Skill Success': props<{ skill: Skill }>(),
    'Add Skill Failure': props<{ error: Error | null }>(),
    'Remove Skill': props<{ skillId: string; agencyId: string }>(),
    'Remove Skill Success': props<{ skillId: string }>(),
    'Remove Skill Failure': props<{ error: Error | null }>(),
    'Update Skill': props<{
      agencyId: string;
      skillId: string;
      skill: Skill;
    }>(),
    'Update Skill Success': props<{
      agencyId: string;
      skillId: string;
      skill: Skill;
    }>(),
    'Update Skill Failure': props<{ error: Error | null }>(),
  },
});
