import { createFeature, createReducer, on } from '@ngrx/store';
import { SkillActions } from './skill.actions';
import { Skill } from 'src/app/shared/models';

export const skillFeatureKey = 'skill';

export interface SkillState {
  skills: Skill[];
  skill: Skill | null;
  loading: boolean;
  error: Error | null;
}

export const initialState: SkillState = {
  skills: [],
  skill: null,
  loading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    SkillActions.loadSkills,
    (state): SkillState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    SkillActions.loadSkillsSuccess,
    (state, { skills }): SkillState => ({
      ...state,
      skills,
      loading: false,
      error: null,
    })
  ),
  on(
    SkillActions.loadSkillsFailure,
    (state, { error }): SkillState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    SkillActions.loadSkill,
    (state): SkillState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    SkillActions.loadSkillSuccess,
    (state, { skill }): SkillState => ({
      ...state,
      skills: [skill],
      skill: skill,
      loading: false,
      error: null,
    })
  ),
  on(
    SkillActions.loadSkillFailure,
    (state, { error }): SkillState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    SkillActions.addSkill,
    (state): SkillState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    SkillActions.addSkillSuccess,
    (state, { skill }): SkillState => ({
      ...state,
      skills: [...state.skills, skill],
      loading: false,
      error: null,
    })
  ),
  on(
    SkillActions.addSkillFailure,
    (state, { error }): SkillState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    SkillActions.removeSkill,
    (state): SkillState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    SkillActions.removeSkillSuccess,
    (state, { skillId }): SkillState => ({
      ...state,
      skills: state.skills.filter(skill => skill.id !== skillId),
      loading: false,
      error: null,
    })
  ),
  on(
    SkillActions.removeSkillFailure,
    (state, { error }): SkillState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    SkillActions.updateSkill,
    (state): SkillState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    SkillActions.updateSkillSuccess,
    (state, { skill }): SkillState => ({
      ...state,
      skills: state.skills.map(item =>
        // item.id === skill.id ? skill : item
        item.id === skill.id ? { ...item, ...skill } : item
      ),
      loading: false,
      error: null,
    })
  ),
  on(
    SkillActions.updateSkillFailure,
    (state, { error }): SkillState => ({
      ...state,
      loading: false,
      error,
    })
  )
);

export const skillFeature = createFeature({
  name: skillFeatureKey,
  reducer,
});
